// Experiment release toggles
export const EXPERIMENT_NAME_RELEASE_SWITCH_TOGGLE = 'release-switch'
export const EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_MARKETPLACE = 'marketplace'
export const EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_SAE_ONLY = 'sae-only'
export const EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_NOW = 'now'
export const EXPERIMENT_NAME_ODS_VC_MODAL = 'wsssh-188'
export const EXPERIMENT_NAME_ERX_ADYEN_MP = 'erx-adyen-mp'
export const EXPERIMENT_NAME_HOME_ONE_ACCOUNT = 'wsssh-2348'
export const EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER = 'cro-1333'
export const EXPERIMENT_NAME_CROSSSELL_LAYOUT = 'cro-1942'
export const EXPERIMENT_NAME_PDP_STOCK_INFORMATION = 'wsawa-3941'
export const EXPERIMENT_NAME_NEW_CDN = 'cro-1881'
export const EXPERIMENT_NAME_NFC_BANNER_ERX_LANDING_PAGE = 'wserx-199'
export const EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW = 'cro-2052'
export const EXPERIMENT_NAME_PDP_DELIVERY_OPTIONS = 'nowcj-2'
export const EXPERIMENT_NAME_ACCOUNT_OVERVIEW_REDPOINTS_WIDGET = 'wscnl-7291'
export const EXPERIMENT_NAME_ACCOUNT_OVERVIEW_NOTEPAD_WIDGET = 'wscnl-7438'
export const EXPERIMENT_NAME_SINGLE_ITEM_CANCELLATION = 'wstra-8615'
export const EXPERIMENT_NAME_NEURAL_SEARCH = 'cro-4106'
export const EXPERIMENT_NAME_CONTACT_PAGE_REDESIGN = 'wscnl-8358'
export const EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST = 'wscnl-8443'
export const EXPERIMENT_NAME_AD_SERVER = 'ad-server'
export const EXPERIMENT_NAME_CART_INTERMEDIATE_DRAWER = 'wscr-3424'
