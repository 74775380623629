import { getCookie, setCookie } from 'cookies-next/client'

import {
  AD_SERVER_BROWSER_ID_CONVERSION_COOKIE,
  AD_SERVER_BROWSER_ID_CONVERSION_COOKIE_MAX_AGE,
  AD_SERVER_BROWSER_ID_TARGETING_COOKIE,
  AD_SERVER_BROWSER_ID_TARGETING_COOKIE_MAX_AGE,
} from './adServerConst'

const adServerCookieSetBrowserIdConversion = (uuid: string): void => {
  setCookie(AD_SERVER_BROWSER_ID_CONVERSION_COOKIE, uuid, {
    httpOnly: false,
    maxAge: AD_SERVER_BROWSER_ID_CONVERSION_COOKIE_MAX_AGE,
    path: '/',
  })
}

const adServerCookieGetAdBrowserIdConversion = (): string | undefined => {
  return getCookie(AD_SERVER_BROWSER_ID_CONVERSION_COOKIE)
}

const adServerCookieGetAdBrowserIdConversionOrGenerate = (): string => {
  const adBrowserIdConversion = adServerCookieGetAdBrowserIdConversion()

  if (adBrowserIdConversion) {
    return adBrowserIdConversion
  }

  const uuid: string = globalThis.crypto.randomUUID()
  adServerCookieSetBrowserIdConversion(uuid)

  return uuid
}

const adServerCookieGetAdBrowserIdTargeting = (): string | undefined => {
  return getCookie(AD_SERVER_BROWSER_ID_TARGETING_COOKIE)
}

const adServerCookieSetAdBrowserIdTargeting = (uuid: string): void => {
  setCookie(AD_SERVER_BROWSER_ID_TARGETING_COOKIE, uuid, {
    httpOnly: false,
    maxAge: AD_SERVER_BROWSER_ID_TARGETING_COOKIE_MAX_AGE,
    path: '/',
  })
}

const COOKIE_NAME_AD_STORAGE = 'ad_storage'
const COOKIE_NAME_USER_CONSENT = 'usercentrics_consent'

export const adServerCookieGetUserConsentForAdStorage = (): boolean => {
  const consentCookie = getCookie(COOKIE_NAME_USER_CONSENT)

  if (typeof consentCookie !== 'string') {
    return false
  }

  try {
    const consent = JSON.parse(consentCookie) as Partial<
      Record<string, boolean>
    >

    return consent[COOKIE_NAME_AD_STORAGE] === true
  } catch {
    return false
  }
}

const adServerCookieGetAdBrowserTargetingIdOrGenerate = ():
  | string
  | undefined => {
  if (!adServerCookieGetUserConsentForAdStorage()) {
    return undefined
  }

  const adBrowserIdTargeting = adServerCookieGetAdBrowserIdTargeting()

  if (adBrowserIdTargeting) {
    return adBrowserIdTargeting
  }

  const uuid = globalThis.crypto.randomUUID()
  adServerCookieSetAdBrowserIdTargeting(uuid)

  return uuid
}

/**
 * checks if generated ids are present on cookies,
 * if present, return them
 * if not generates, saves them on cookies and returns.
 */
export const adServerCookiesGetOrGenerateUserIdentity = (): {
  browserIdConversion: string
  browserIdTargeting?: string
} => {
  return {
    browserIdConversion: adServerCookieGetAdBrowserIdConversionOrGenerate(),
    browserIdTargeting: adServerCookieGetAdBrowserTargetingIdOrGenerate(),
  }
}
