import { deleteCookie, getCookie, setCookie } from 'cookies-next/client'

import {
  AD_SERVER_CAMPAIGN_CODE_COOKIE,
  AD_SERVER_CAMPAIGN_CODE_EXPIRY_IN_MS,
} from './adServerConst'
import { type AdServerCampaignCodes } from './model/adServerTypes'

type AdServerCampaignCodesEntry = [
  name: keyof AdServerCampaignCodes,
  date: AdServerCampaignCodes[keyof AdServerCampaignCodes],
]

/**
 * Get the campaign codes from the cookie
 */
const getCampaignsFromCookie = (): AdServerCampaignCodesEntry[] => {
  const cookieValue = getCookie(AD_SERVER_CAMPAIGN_CODE_COOKIE)

  if (!cookieValue) {
    return []
  }

  try {
    return Object.entries(JSON.parse(cookieValue) as AdServerCampaignCodes)
  } catch {
    return []
  }
}

/**
 * Get the campaign code from the query parameters and store it in a cookie
 */
export const adServerGetCampaignCode = (campaignCode?: string): string[] => {
  const campaignCodesWithExpiryDate = getCampaignsFromCookie()

  const now = Date.now()

  const activeCampaignEntries = campaignCodesWithExpiryDate.filter(
    ([, date]) => date > now,
  )

  if (campaignCode) {
    activeCampaignEntries.push([
      campaignCode,
      now + AD_SERVER_CAMPAIGN_CODE_EXPIRY_IN_MS,
    ])
  }

  if (activeCampaignEntries.length === 0) {
    deleteCookie(AD_SERVER_CAMPAIGN_CODE_COOKIE)
  } else {
    setCookie(
      AD_SERVER_CAMPAIGN_CODE_COOKIE,
      JSON.stringify(Object.fromEntries(activeCampaignEntries)),
      {
        httpOnly: false,
        maxAge: AD_SERVER_CAMPAIGN_CODE_EXPIRY_IN_MS,
        path: '/',
      },
    )
  }

  return activeCampaignEntries.map(([name]) => name)
}
